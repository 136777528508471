import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AnimationController, NavController} from '@ionic/angular';
import {JsonPipe} from '@angular/common';

@Component({
  selector: 'app-menu-pizze',
  templateUrl: './menu-pizze.page.html',
  styleUrls: ['./menu-pizze.page.scss'],
})
export class MenuPizzePage implements OnInit {
  public pizze: any;
  public focaccia: any;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('header') header: HTMLElement;
  public animation: any;

  // eslint-disable-next-line max-len
  public prezzo = true;

  // eslint-disable-next-line max-len
  constructor(private animationCtrl: AnimationController, public json: JsonPipe, public element: ElementRef, public renderer: Renderer2, public navCtrl: NavController) {


  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.animation = this.animationCtrl.create()
      .addElement(document.querySelectorAll('.to-1'))
      .duration(2000)
      .fromTo('opacity', '0', '1');
    this.animation.play();


    this.pizze = [
      {
        nomePiatto: 'Marinara. Zero',
        // eslint-disable-next-line max-len
        ingredienti: 'Pomodoro San Marzano-Aglio di Caraglio- Origano di montagna- Capperi di Salina-Olive taggiasche liguri- Alici di Trapani',
        allergeni: '',
        smallText: 'tomato sauce garlic-origan-capers-olives-anchovies',
        prezzo: 7,
      },
      {
        nomePiatto: 'Margherita',
        ingredienti: 'Pomodoro San Marzano-fior di latte-Olio evo - Basilico',
        allergeni: '',
        smallText: 'tomato sauce-mozzarella cheese extravirgin olive oil-basil',
        prezzo: 8,
      },
      {
        nomePiatto: 'Mediterranea',
        ingredienti: 'Pomodoro San Marzano-origano di collina- mazzancolle mediterraneo-ricotta di bufala-pomo confite-olio al prezzemolo',
        allergeni: '',
        smallText: 'tomato sauce origan-prawns-buffalo ricotta cheese-tomatoes confite- oil -parslei',
        prezzo: 13,
      },
      {
        nomePiatto: 'Salsiccia di Bra',
        ingredienti: 'Pomodoro San Marzano- fior di latte- Salsiccia di vitello di Bra',
        allergeni: '',
        smallText: 'tomato sauce-mozzarella cheese-beef sausage',
        prezzo: 10,
      },
      {
        nomePiatto: 'Le 4 stagioni d’Italia',
        // eslint-disable-next-line max-len
        ingredienti: 'Pomodoro San Marzano- fior di latte- Prosciutto cotto rosa- Funghi-Olive taggiasche liguri-Carciofi spirito contadino',
        allergeni: '',
        smallText: 'tomato sauce-mozzarella cheese-ham-mushrooms-olives-artichokes',
        prezzo: 10,
      },
      {
        nomePiatto: 'Sapori di Calabria',
        ingredienti: 'Pomodoro San Marzano- Mozzarella di Bufala Campana- Pomodori secchi- Nduja di Spilinga d.o.p.- Soppressata Calabrese',
        allergeni: '',
        smallText: 'tomato sauce buffalo mozzarella cheese-dried tomatoes-spicy spreadable salami-spicy salami',
        prezzo: 10,
      },
      {
        nomePiatto: 'Friarielli e salsiccia di Bra',
        ingredienti: 'Pomodoro San Marzano- fior di latte-Friarielli di Aversa- Salsiccia di Bra',
        allergeni: '',
        smallText: 'tomato sauce-mozzarella cheese-friarielli-sausage',
        prezzo: 10,
      },
      {
        nomePiatto: 'Mas...Calzone',
        ingredienti: 'Pomodoro San Marzano-Fior di latte-cotto rosa-olive taggiasche liguri',
        allergeni: '',
        smallText: 'tomato sauce-mozzarella cheese-ham-olives',
        prezzo: 10,
      },
      {
        nomePiatto: 'Bufala',
        ingredienti: 'Pomodoro San Marzano- Mozzarella di Bufala Campana -Olive taggiasche liguri',
        allergeni: '',
        smallText: 'tomato sauce-buffalo mozzarella-olives',
        prezzo: 10,
      },
      {
        nomePiatto: 'Margari di montagna',
        ingredienti: 'Pomodoro San Marzano- Mozzarella fior di latte- Gorgonzola Dolce-Toma d’Alpeggio-Sajras Val Pellice',
        allergeni: '',
        smallText: 'tomato sauce-mozzarella cheese gorgonzola cheese-tome cheese-sajras cheese',
        prezzo: 10,
      },
      {
        nomePiatto: 'Spirito Contadino',
        ingredienti: 'Crema&Verdure di stagione-Stracchino di Entracque-Sajras Vall Pellice',
        allergeni: '',
        smallText: 'vegetable sauce-mixed vegetables-stracchino cheese-sajras cheese',
        prezzo: 10,
      },
      {
        nomePiatto: 'Wudy&Chips',
        ingredienti: 'Pomodoro San Marzano-Fior di latte-Wurstel-Patatine fritte',
        allergeni: '',
        smallText: 'tomato sauce-mozzarella cheese-wiener sausage-french fries',
        prezzo: 10,
      },
      {
        nomePiatto: 'Crudo di Parma 24mesi & Burrata Pugliese',
        ingredienti: 'Pomodoro San Marzano-Burrata Pugliese-Prosciutto Crudo di Parma d.o.p',
        allergeni: '',
        smallText: 'tomato sauce-burrata cheese-seasoned ham',
        prezzo: 14,
      },
      {
        nomePiatto: 'Jamon Serrano gran Riserva Montesano',
        ingredienti: 'Fior di latte-Cipolla caramellata-Jamon Serrano Gran Riserva-Peperoncino',
        allergeni: '',
        smallText: 'mozzarella cheese-caramelized red onion-Spanish seasoned ham-chili',
        prezzo: 16,
      },
      {
        nomePiatto: 'Carbonara',
        ingredienti: 'Fior di latte-Pecorino Romano-Guanciale di Mangalica-Tuorlo bio-pepe nero',
        allergeni: '',
        smallText: 'mozzarella cheese-pecorino cheese-lard-yolk-black pepper',
        prezzo: 12,
      },
      {
        nomePiatto: 'Antico Magno',
        ingredienti: 'Fior di latte- Scamorza affumicata dop- Sajras Val Pellice-prosciutto cotto al forno Antico Magno in uscita',
        allergeni: '',
        smallText: 'mozzarella cheese-smoked scamorza cheese-sajras cheese-smoked ham',
        prezzo: 13,
      },
      {
        nomePiatto: 'Parmigiana',
        ingredienti: 'Pomodoro San Marzano-Bufala campana-Melanzane Fritte-Parmigiano Reggiano',
        allergeni: '',
        smallText: 'tomato sauce- buffalo mozzarella cheese-fried eggplants- parmesan cheese',
        prezzo: 13,
      },
      {
        nomePiatto: 'Nostromo a Tropea',
        ingredienti: 'Pomodoro Picadilly cotto - Tonno Ortiz - Cipolla rossa di Tropea - Valeriana - Bufala in uscita',
        allergeni: '',
        smallText: 'picadilly cooked tomatoes-tuna-red onion- cornsalad- buffalo mozzarella cheese',
        prezzo: 13,
      },
      {
        nomePiatto: 'Napolé',
        ingredienti: 'Pomodoro San Marzano-Bufala campana- Acciughe dell’Adriatico-Pomodorini confite- Basilico',
        allergeni: '',
        smallText: 'tomato sauce-buffalo mozzarella-anchiovies- tomatoes confite-basil',
        prezzo: 13,
      },
      {
        nomePiatto: 'Tartufo',
        ingredienti: 'Fior di latte- Fontina dei monti- Burro demisel- Tartufo nero pregiato',
        allergeni: '',
        smallText: 'mozzarella cheese-fontina cheese-butter-black truffle',
        prezzo: 20,
      },
      {
        nomePiatto: 'Funghi porcini',
        ingredienti: 'Fior di latte - funghi porcini - ricotta di bufala',
        allergeni: '',
        smallText: 'mozzarella cheese-cardoncellii mushrooms-buffalo ricotta cheese',
        prezzo: 14,
      },
    ];


    this.focaccia = [
      {
        nomePiatto: 'Lard d’Muncalè & Gorgonzola',
        ingredienti: 'Focaccia bianca-Lardo di Moncalieri-Gorgonzola',
        allergeni: '',
        smallText: 'focaccia-Lard-gorgonzola cheese',
        prezzo: 8,
      },
      {
        nomePiatto: 'Coda nera',
        ingredienti: 'Focaccia bianca-Salmone Coda Nera-Cipolle di tropea- Burro demisel- Panna-sakura mix',
        allergeni: '',
        smallText: 'focaccia- smoked salmon-red onions- salted french butter heavy cream',
        prezzo: 12,
      },
      {
        nomePiatto: 'La Fresca',
        ingredienti: 'Focaccia bianca-Pomodorini confite-Burrata Pugliese-olive taggiasche liguri -basilico',
        allergeni: '',
        smallText: 'focaccia – confite tomatoes-burrata cheese-olives',
        prezzo: 10,
      },
      {
        nomePiatto: 'Pistacchio di Bronte',
        ingredienti: 'Focaccia bianca-Stracchino-Mortadella di Mangalica-Pesto di Pistacchio',
        allergeni: '',
        smallText: 'focaccia -stracchino cheese- mortadella-pistachios pesto',
        prezzo: 9,
      },
      {
        nomePiatto: 'Crudo di parma & Burrata Pugliese',
        ingredienti: 'Focaccia bianca-Burrata Pugliese-Crudo di Parma',
        allergeni: '',
        smallText: 'focaccia-burrata cheese-Parma ham',
        prezzo: 13,
      },
      {
        nomePiatto: 'Vitello tonnato & cappero di Salina',
        ingredienti: 'Focaccia bianca-Rotonda rosa-Salsa tonnata-Capperi di Salina',
        allergeni: '',
        smallText: 'focaccia-sliced veal with tuna &anchovy sauce',
        prezzo: 14,
      },
    ];
  }

  doRefresh($event: any) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  ionViewWillEnter() {
    // this.renderer.setStyle(this.header['el'], 'webkitTransition', 'all 700ms');
  }

  onContentScroll(event) {
    // if (event.detail.scrollTop >= 50) {
    //   this.renderer.setStyle(this.header['el'], 'display', 'unset');
    // } else {
    //   this.renderer.setStyle(this.header['el'], 'display', 'none');
    // }
  }

  goBack(url: string, isPath = true) {
    if (isPath) {
      this.navCtrl.navigateBack(url);
    } else {
      window.open(url, '_blank');
    }
  }

}
