import {Component} from '@angular/core';
import {NavController} from "@ionic/angular";

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  constructor(public navCtrl: NavController) {
  }

  doRefresh($event: any) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  goTo(url: string, isPath = true) {
    if (isPath) {
      this.navCtrl.navigateForward(url);
    } else {
      window.open(url, '_blank');
    }
  }

}
