import {Component, OnInit} from '@angular/core';
import {NavController} from "@ionic/angular";

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.page.html',
  styleUrls: ['./contatti.page.scss'],
})
export class ContattiPage implements OnInit {

  constructor(public navCtrl: NavController) {
  }

  ngOnInit() {
  }

  doRefresh($event: any) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  goBack(url: string, isPath = true) {
    if (isPath) {
      this.navCtrl.navigateBack(url);
    } else {
      window.open(url, '_blank');
    }
  }

}
