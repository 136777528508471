import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {JsonPipe} from '@angular/common';
import {AnimationController, NavController} from '@ionic/angular';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  public antipasti: any;
  public conviviali: any;
  public pesce: any;
  public primiPiatti: any;
  public prezzo = false;
  public secondi: any;
  public secondiPesci: any;
  public desserts: any;
  public taglieri: any;

  @ViewChild('header') header: HTMLElement;
  public animation: any;

  constructor(private animationCtrl: AnimationController, public json: JsonPipe, public element: ElementRef, public renderer: Renderer2, public navCtrl: NavController) {


  }

  ngOnInit() {

  }

  ionViewDidEnter() {
    this.animation = this.animationCtrl.create()
      .addElement(document.querySelectorAll('.to-1'))
      .duration(2000)
      .fromTo('opacity', '0', '1');
    this.animation.play();


    this.antipasti = [
      {
        nomePiatto: 'La Rotonda rosa cotta in crosta di sale',
        ingredienti: 'salsa all’antica',
        allergeni: '*3',
        smallText: 'Sliced veal - Traditional tuna sauce',
        prezzo: 0,
      },
      {
        nomePiatto: 'Cruda di Fassona battuta al coltello - uovo a sorpresa - nocciola igp',
        ingredienti: 'nocciole uovo',
        allergeni: '*3-7-8',
        smallText: 'Fassona raw meat - quail egg - hazelnut crumble',
        prezzo: 13,
      },
      {
        nomePiatto: 'Salmon Salad',
        ingredienti: 'salmone affumicato coda nera - avocado - melograno - caprino',
        allergeni: '*2-4-12',
        smallText: 'Salmon salad - smoked salmon with avocado - pomegrade - Caprino cheese',
        prezzo: 18,
      },
    ];
    this.primiPiatti = [
      {
        nomePiatto: 'Pappardelle al ragù',
        ingredienti: 'Salsiccia di Bra',
        allergeni: '*1-3-7-9',
        smallText: 'Fresh homemade egg pasta Pappardelle - Bra sausage ragout',
        prezzo: 20,
      },
      {
        nomePiatto: 'Agnolotti del “Plin”',
        ingredienti: 'Sugo d\'arrosto',
        allergeni: '*1-3-7-8-9-12',
        smallText: 'Fresh homemade egg pasta Ravioli – braised reduction sauce',
        prezzo: 20,
      },
      {
        nomePiatto: 'Quadrotti di carciofi',
        ingredienti: 'crema di roccaverano – perlage di tartufo nero',
        allergeni: '*1-2-3-12',
        smallText: 'Fresh homemade pasta Quadrotti made with artichokes and borage - cream of Roccaverano cheese - black truffle',
        prezzo: 25,
      },

    ];

    this.secondi = [
      {
        nomePiatto: 'Maialino da latte al forno e gratin di topinambur',
        ingredienti: '',
        allergeni: '*1-3-5-7-11',
        smallText: 'Baked suckling pig and jerusalem artichoke gratin',
        prezzo: '14',
      },
      {
        nomePiatto: 'L’eleganza del mare',
        ingredienti: 'fritto di gamberi CrystalBlue - Calamari Nostrani - Sarde',
        allergeni: '*1-2-4-5-12',
        smallText: 'Fried shrimps – squid – sardines',
        prezzo: '24',
      },
    ];

    this.taglieri = [
      {
        nomePiatto: 'Tagliere mini – focaccia al pistacchio',
        ingredienti: 'mortedella Favola gran riserva – stracciatella di Burrata',
        allergeni: '',
        smallText: 'Platter board with - pistachio focaccia - mortadella - Burrata stracciatella',
        prezzo: false,
      },
      {
        nomePiatto: 'Tagliere mini – Culatta cotta Maletti – bocconcini fritti',
        ingredienti: '',
        allergeni: '',
        smallText: 'Platter board with - ham San Giovanni - fried bites',
        prezzo: false,
      },
      {
        nomePiatto: 'Tagliere mini formaggi',
        ingredienti: 'parmigiano stagionato - gorgonzola dolce - caprino',
        allergeni: '',
        smallText: 'Platter board with selection of cheese - aged parmesan - sweet gorgonzola - caprino',
        prezzo: false,
      },
      {
        nomePiatto: 'Tagliere mini focaccia alle erbe',
        ingredienti: 'gorgonzola - lardo valle Varaita',
        allergeni: '',
        smallText: 'Platter board with - focaccia with herbs - gorgonzola - lard',
        prezzo: false,
      },
      {
        nomePiatto: 'Tagliere Piemontese',
        ingredienti: 'tipica selezione di salumi e prosciutti dalle valli Cuneesi',
        allergeni: '',
        smallText: 'Platter board with a typical selection of local salami and ham',
        prezzo: false,
      },
      {
        nomePiatto: 'Tagliere crudo di Lurisia',
        ingredienti: 'insalata russa',
        allergeni: '',
        smallText: 'Platter board with raw ham - russian salad',
        prezzo: false,
      },
      {
        nomePiatto: 'Tagliere cotto Praga Maletti',
        ingredienti: 'formaggetta di langa alla griglia – antipasto rosso Piemontese',
        allergeni: '',
        smallText: 'Platter board with Ham - grilled cheese Formaggetta - sweet and sour seasonal vegetables with red sauce\n',
        prezzo: false,
      },
      {
        nomePiatto: 'Tagliere dei Margari',
        ingredienti: 'selezione di formaggi pregiati accompagnati da miele – cugnà di nebbiolo',
        allergeni: '',
        smallText: 'Platter board with selection of fine cheese - honey - Nebbiolo grape mustard',
        prezzo: false,
      },
      {
        nomePiatto: 'Tagliere di Zibello',
        ingredienti: 'culatello di Zibello – bufala Moris – acciughe Sicilia',
        allergeni: '',
        smallText: 'Platter board with – crudo Culatello – cheese bufala – anchovies fisch Sicily',
        prezzo: false,
      },
      {
        nomePiatto: 'Tagliere crudo di Lurisia vs jamon Serrano',
        ingredienti: ' fonduta di Raschera – insalata belga',
        allergeni: '',
        smallText: 'Selection of Crudo di Parma ham and Jamon Serrano - Raschera fondue - belgian endive',
        prezzo: false,
      },
      {
        nomePiatto: 'Tagliere gran Gourmet',
        ingredienti: 'selezione di salumi – selezione di formaggi freschi/stagionati',
        allergeni: '',
        smallText: 'Platter board with selection of local salumi and cheese',
        prezzo: false,
      },
    ];

    this.desserts = [
      {
        nomePiatto: 'Ti - ra - mi - sù',
        ingredienti: '',
        allergeni: '*1-3-7',
        smallText: 'Typical dessert with coffee – Biscuits - Special cream topped with cocoa',
        prezzo: '8',
      },
      {
        nomePiatto: 'Lingotto del Piemonte',
        ingredienti: 'pesche del Roero',
        allergeni: '*1-3-7-8',
        smallText: 'Typical chocolate flan - Amaretti biscuits – sour cherries – candied chestnut',
        prezzo: '8',
      },
      {
        nomePiatto: 'Panna Cotta',
        ingredienti: 'Coulis frutti rossi - meringhe',
        allergeni: '*3-7-8',
        smallText: 'Panna cotta - Red Fruits sauce - Meringue',
        prezzo: '8',
      },
      {
        nomePiatto: 'Tarte tatin con gelato alla crema',
        ingredienti: '',
        allergeni: '*1-3-7',
        smallText: 'Tarte tatin with vanilla ice cream',
        prezzo: '9',
      },
      {
        nomePiatto: 'Sorbetto di frutta naturale',
        ingredienti: '',
        allergeni: '*8',
        smallText: 'Fresh fruit sorbetto - almond crumble',
        prezzo: '5',
      },
    ];
  }

  doRefresh($event: any) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  ionViewWillEnter() {
    // this.renderer.setStyle(this.header['el'], 'webkitTransition', 'all 700ms');
  }

  onContentScroll(event) {
    // if (event.detail.scrollTop >= 50) {
    //   this.renderer.setStyle(this.header['el'], 'display', 'unset');
    // } else {
    //   this.renderer.setStyle(this.header['el'], 'display', 'none');
    // }
  }

  goBack(url: string, isPath = true) {
    if (isPath) {
      this.navCtrl.navigateBack(url);
    } else {
      window.open(url, '_blank');
    }
  }
}
