import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HomePage} from './home/home.page';
import {ContattiPage} from './contatti/contatti.page';
import {MenuPage} from './menu/menu.page';
import {JsonPipe} from '@angular/common';
import {MenuPizzePage} from "./menu-pizze/menu-pizze.page";

@NgModule({
  declarations: [AppComponent, HomePage, ContattiPage, MenuPage, MenuPizzePage],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule],
  providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, JsonPipe],
  bootstrap: [AppComponent],
})
export class AppModule {
}
