import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {HomePage} from './home/home.page';
import {ContattiPage} from './contatti/contatti.page';
import {MenuPage} from './menu/menu.page';
import {MenuPizzePage} from './menu-pizze/menu-pizze.page';

const routes: Routes = [
  {path: '', component: HomePage,},
  {path: 'contatti', component: ContattiPage},
  {path: 'menu', component: MenuPage},
  {path: 'menu-pizze', component: MenuPizzePage},
  {path: '**', redirectTo: ''},
  {path: 'home', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
